
import { defineComponent } from 'vue';
import PageHeader from '../components/PageHeader.vue'

export default defineComponent({
  name: 'VouchersThanks',
  components: {
    PageHeader
  },
  mounted() {
    const w = window as any;
    w.fbq('track', 'Purchase', { value: 0.00, currency: 'GBP' });
  }
});
